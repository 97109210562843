import { $_app_server } from '@/_helpers'

export const networkService = {
  list,
  saveNewNetwork,
  deleteNetwork
}

let f_resp = (response) => response.data;

function list() {
  return $_app_server.get("network/admins").then(f_resp)
}

function saveNewNetwork(obj) {
  return $_app_server.post("network/add", obj).then(f_resp)
}

function deleteNetwork(id) {
  return $_app_server.delete(`network/delete/${id}`).then(f_resp)
}