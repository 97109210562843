import { $_app_server } from '@/_helpers'

export const productCategoryService = {
  loadAllCategories,
  addProductCategory,
  deleteProductCategory
}

let f_resp = (response) => response.data;

function loadAllCategories() {
  return $_app_server.get("product/getAllProductCategories").then(f_resp)
}

function addProductCategory(obj) {
  return $_app_server.post("category/addProductCategory", obj).then(f_resp)
}

function deleteProductCategory(categoryId) {
  return $_app_server.delete(`category/delete/${categoryId}`).then(f_resp)
}
