import { $_app_server } from '@/_helpers'

export const pointService = {
  list,
  types,
  remove,
  create,
  update,
  cities,
  streets,
  houses,
  tradepointListByCity,
  tradepointListByRegion,
  listByProjectAndNetwork
}

let f_resp = (response) => response.data;

// function list(sortName, sortDir) {
//   return $_app_server.get(`tradepoint/all?sortName=${sortName}&sortDir=${sortDir}`).then(f_resp)
// }

function list() {
  return $_app_server.get(`tradepoint/all`).then(f_resp)
}

function types() {
  return $_app_server.get("tradepoint/types").then(f_resp)
}

function cities() {
  return $_app_server.get("tradepoint/city").then(f_resp)
}

function streets() {
  return $_app_server.get("tradepoint/street").then(f_resp)
}

function houses() {
  return $_app_server.get("tradepoint/house").then(f_resp)
}

function remove(id) {
  return $_app_server.delete(`tradepoint/${id}`).then(f_resp)
}

function create(obj) {
  return $_app_server.post(`tradepoint`, obj).then(f_resp)
}

function update(obj) {
  return $_app_server.put(`tradepoint`, obj).then(f_resp)
}

function tradepointListByCity(network_id, city_id, project_id, region_id) {
  return $_app_server.get(`tradepoint/filteredListByCity/${network_id}/${project_id}?city_id=${city_id}&region_id=${region_id}`).then(f_resp)
}

function tradepointListByRegion(network_id, region_id, project_id) {
  return $_app_server.get(`tradepoint/filteredListByRegion/${network_id}/${region_id}/${project_id}`).then(f_resp)
}

function listByProjectAndNetwork(network_id, project_id) {
  return $_app_server.get(`tradepoint/filteredListByProjectAndNetwork/${network_id}/${project_id}`).then(f_resp)
}