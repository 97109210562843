export const sp = {
  namespaced: true,
  state: {
    sps: []
  },
  getters: {},
  actions: {},
  mutations: {
    setSps(state, list) {
      state.sps = [].concat(list)
    },
    addSp(state, item) {
      state.sps.push(item)
    },
  }
}