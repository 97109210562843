import { cityService } from '@/_services'
export const city = {
  namespaced: true,
  state: {
    cities: [],
  },
  getters: {
  },
  mutations: {
    setCities(state, list) {
      state.cities = list
    },

    editCity(state, editCity) {
      let index = state.cities.findIndex(elem => elem.id === editCity.id)
      state.cities.splice(index, 1, editCity)
    },

    deleteCity(state, cityId) {
      let index = state.cities.findIndex((city) => city.id == cityId)
        if(index != -1) {
          state.cities.splice(index, 1)
        }
    },

    addNewCity(state, newCity) {
      state.cities.push(newCity)
    }
  },

  actions: {
    loadAllCities({commit}){
      return cityService.getAllCities().then(data => {
        commit("setCities", data)
      })
    },

    addCity({commit}, city) {
      return cityService.addCity(city).then((data) => {
        commit("addNewCity", data)
      })
    },

    editCity({commit}, city) {
      return cityService.addCity(city).then((data) => {
        commit("editCity" , data)
      })
    },


    deleteCity({commit},id){
      return cityService.removeCity(id).then(() => {
        commit("deleteCity", id)
      })
    }
  },

    
}