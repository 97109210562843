<template lang="pug">
b-form-group( :label="label" label-cols="4" label-align="right" )
  b-form-input( :value="value" :state="valid" v-mask="mask" size="sm" @input="input" )
  b-form-invalid-feedback.mt-0( v-if="disabledErrorMessage" id="input-live-feedback")
    span {{ errorMessage }}
</template>

<script>
export default {
  props: {
    value: null,
    labal: null,
    label: null,
    mask: null,
    valid: null,
    disabledErrorMessage: true,
    errorMessage: null
  },
  
  methods: {
    input(val) {
      this.$emit('input', val)
    },
  }
}
</script>