import { config } from '@/_helpers'
import { userService } from '@/_services'
import axios from "axios"

let axiosHeaders = { 'Content-Type': 'application/json' }

let token = localStorage.getItem('access_token')
if (token && token.length > 0) {
  axiosHeaders.Authorization = 'Bearer ' + token
}

export const $_app_server = axios.create({
  baseURL: config.apiUrl,
  headers: axiosHeaders
})

$_app_server.interceptors.response.use(response => response, error => errorHandler(error))

function errorHandler (error) {
  if (error.response!== undefined && error.response.status === 401) {
    userService.logout()
  }
  return Promise.reject(error)
}