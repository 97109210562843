import { $_app_server } from '@/_helpers'

export const spService = {
  list,
  get,
  acts,
  remove,
  save,
  removePhoto
}

let f_resp = (response) => response.data;

function list() {
  return $_app_server.get("sp/list").then(f_resp)
}

function get(id) {
  return $_app_server.get(`sp/${id}`).then(f_resp)
}

function acts() {
  return $_app_server.get("sp/acts").then(f_resp)
}

function remove(id) {
  return $_app_server.delete(`sp/${id}`).then(f_resp)
}

function save(obj) {
  return $_app_server.post(`sp`, obj).then(f_resp)
}

function removePhoto(spId, photoUuid) {
  return $_app_server.delete(`sp/${spId}/photo/${photoUuid}`).then(f_resp)
}