import { $_app_server } from '@/_helpers'

export const streetsService = {
  saveStreet,
  removeStreet,
  getAllStreets,
  addStreet
}


function getAllStreets() {
  return $_app_server.get("address/street/streetsList")
    .then(response => {
      return response.data
    })
}

function saveStreet(profile) {
  return $_app_server.post(`исходник`, profile).then((response) => response.data)
}

function removeStreet(id) {
  return $_app_server.delete(`address/street/deleteStreet/${id}`).then((response) => response.data)
}

function addStreet(obj) {
  return $_app_server.post(`address/street/addStreet`, obj).then((response) => response.data)
}