import { $_app_server } from '@/_helpers'

export const RegionService = {
  getAllRegions,
  saveRegion,
  removeRegion,
}


function getAllRegions() {
  return $_app_server.get(`address/region/regionList`)
    .then(response => {
      return response.data
    })
  }

function saveRegion(obj) {
  return $_app_server.post(`address/region/addRegion`, obj).then((response) => response.data)
}

function removeRegion(id) {
  return $_app_server.delete(`address/region/deleteRegion/${id}`).then((response) => response.data)
}