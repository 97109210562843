import { streetsService } from "../_services";

export const street = {
  namespaced: true,
  
  state: {
    streets: [],
  },
  
  getters: {},

  mutations: {
    setStreets(state, list) {
      state.streets = [].concat(list)
      state.streets.sort((u1, u2) => u1.name > u2.name ? 1 : u1.name < u2.name ? -1 : 0);
    },

    deleteStreet(state, streetId) {
      let index = state.streets.findIndex(el => el.id === streetId)
      if(index != -1) {
        state.streets.splice(index, 1)
      }
    },

    setNewOptionForStreet(state, street) {
      let index = state.streets.findIndex(el => el.id === street.id)
      state.streets.splice(index, 1, street)
    },

    setNewStreet(state, street) {
      state.streets.push(street)
    }
  },
  
  actions: {
    getStreets({commit}) {
      return streetsService.getAllStreets().then(data => {
        commit('setStreets', data)
      })
    },

    removeStreet({commit}, id) {
      return streetsService.removeStreet(id).then(() => {
        commit("deleteStreet", id)
      })
    },

    addStreet({commit}, street) {
      return streetsService.addStreet(street).then(data => {
        commit("setNewStreet", data)
      })
    },

    editStreet({commit}, street) {
      return streetsService.addStreet(street).then(data => {
        commit("setNewOptionForStreet", data)
      })
    }
  }
}