<template lang="pug">
b-icon( :icon="icon" :variant="variant" font-scale="1.7" )
</template>

<script>
export default {
  props: ['active'],

  computed: {
    icon() {
      return this.active ? 'check-circle' : 'x-circle'
    },

    variant() {
      return this.active ? 'success' : 'danger'
    },
  },
}
</script>