<template lang="pug">
b-card-footer
  b-navbar
    b-navbar-nav( class="ml-auto" ) Количество записей: 
      b( class="ml-2" ) {{ count }}
</template>

<script>
export default {
  props: ['count'],
}
</script>