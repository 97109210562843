<template lang="pug">
app-form-modal-dialog( :value="value" :title="titleDialog" :loading="loading" size="xl" @input="input" @save="save")
  b-card-group( class="mb-4" deck )
    b-card( :header="leftHeader" body-class="m-card-body" )
      template( v-for="item in leftList" )
        b-button( 
          :key="item.id" 
          variant='outline-success'
          block 
          @click="moveToRight(item)"
        ) {{ item.name }}
    b-card( :header="rightHeader" body-class="m-card-body" )
      template( v-for="item in rightList" )
        b-button( 
          :key="item.id" 
          variant='outline-success'
          block 
          @click="moveToLeft(item)"
        ) {{ item.name }}
</template>
      
<script>

export default {
  props: ['id', 'value', 'allItems', 'savedItems', 'titleDialog', 'leftHeader', 'rightHeader'],

  components: {
  },

  data () {
    return {
      rightList: [],
      leftList: [],
      loading: false,
    }
  },

  mounted() {
    this.rightList = [...this.savedItems ? this.savedItems : []]
    this.leftList = [...this.rightList.length > 0 ? this.allItems.filter(item => !this.rightList.some(it => it.id == item.id)) : this.allItems]
  },

  computed: {
  },

  methods: {
    input(newValue) {
        this.$emit('input', newValue)
    },

    moveToLeft(item) {
      this.moveItem(item, this.rightList, this.leftList)
    },

    moveToRight(item) {
      this.moveItem(item, this.leftList, this.rightList)
    },

    moveItem(item, ownSideBuffer, otherSideBuffer) {
      let index = ownSideBuffer.findIndex(el => el == item)
      ownSideBuffer.splice(index, 1)
      otherSideBuffer.push(item)
    },

    save() {
      this.$emit('save', this.rightList)
    },
  }
}
</script>

<style scoped>
.m-card-body {
  max-height: calc(100vh - 17rem);
  min-height: calc(100vh - 17rem);
  overflow: auto;
}
</style>