import { userService } from '@/_services'
export const user = {
  namespaced: true,
  state: {
    ready: false,
    users: [],
    cities: [],
  },
  getters: {
    getUsers(state, filter) {
      let lowercasedFilter = filter.trim().toLowerCase()
      return lowercasedFilter ? state.users.filter(a => a.username.toLowerCase().includes(lowercasedFilter)) : state.users
    },
  },
  mutations: {
    setUsers(state, list) {
      state.users = [].concat(list)
      state.users.sort((u1, u2) => u1.name > u2.name ? 1 : u1.name < u2.name ? -1 : 0);
      state.ready = true
    },

    setUserHash(state, hash) {
      state.users.hash = hash
    },

    setCities(state, list) {
      state.cities = list
    },

    setNewUser(state, newUser) {
      state.users.push(newUser)
    },

    removeUser(state, userId) {
      let index = state.users.findIndex((item) => item.id === userId)
      if (index !== -1) {
        state.users.splice(index, 1)
      }
    },

    updateUser(state, user){
      let index = users.findIndex((item) => item.id == user.id)
      state.splice(index, 1, d)
    }
  },

  actions: {
    loadAllUserAndProfile({ commit }) {
      return userService.listCheckProfiles().then(data => {
        data.map(el => {
          let hash = Object.values(el).join('$')
          el.hash = hash
        })
        commit("setUsers",data)
      })
    },

    addUser({commit}, user) {
      return userService.addUser(user).then(data => {
        commit("setNewUser", data)
      })
    },

    saveProfile({}, userId) {
      return userService.saveProfile(userId).then(data => {
        commit("updateUser", data)
      })
    },

    deleteUser({commit}, id) {
      return userService.removeUser(id).then(() => {
        commit("removeUser", id)
      })
    },
  },

    
}