<template lang="pug">
b-navbar-nav
  b-form-input( :value="value" class="mr-sm-2 search-input" :placeholder="placeholder" @input="input" )
  button( v-show="value" type="button" class="close clear-search-button" aria-label="Очистить" @click="clear" )
    span( class="clear-search-icon" aria-hidden="true" ) &times;
</template>

<script>
export default {
  props: ['value', 'placeholder'],

  methods: {
    input(newValue) {
      this.$emit('input', newValue)
    },

    clear() {
      this.$emit('input', '')
    }
  },
}
</script>

<style scoped>
.search-input {
  padding-right: 1rem;
}
.clear-search-button {
  position: absolute;
  right: 25px;
  top: 15px;
}
.clear-search-icon {
  background-color: white;
  padding-left: 4px;
  padding-right: 4px;
}
</style>