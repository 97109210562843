import { userService } from '@/_services'
export const roles = {
  namespaced: true,
  state: {
    roles: []
  },
  getters: {},
  mutations: {
    setRoles(state, list) {
      let newList = list.filter(el => el.name != "NONE")
      state.roles = newList
    }
  },

  actions: {
    loadAllRoles({commit}) {
      return userService.getAllRoles().then(data => {
        commit('setRoles', data)
      }) 
    }
  },
}