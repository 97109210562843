import { RegionService } from "../_services";

export const region = {
  namespaced: true,
  
  state: {
    regions: null
  },
  
  getters: {},

  mutations: {
    setRegions(state, list) {
      state.regions = [].concat(list)
      state.regions.sort((u1, u2) => u1.name > u2.name ? 1 : u1.name < u2.name ? -1 : 0);
    },

    deleteRegion(state, regionId) {
      let index = state.regions.findIndex(el => el.id === regionId)
      if(index != -1) {
        state.regions.splice(index, 1)
      }
    },

    setNewOptionForRegion(state, region) {
      let index = state.regions.findIndex(el => el.id === region.id)
      state.regions.splice(index, 1, region)
    },

    setNewRegion(state, region) {
      state.regions.push(region)
    }
  },
  
  actions: {
    getRegions({commit}) {
      return RegionService.getAllRegions().then(data => {
        commit('setRegions', data)
      })
    },

    removeRegion({commit}, id) {
      return RegionService.removeRegion(id).then(() => {
        commit('deleteRegion',id)
      })
    },

    addRegion({commit}, region) {
      return RegionService.saveRegion(region).then(data => {
        commit('setNewRegion', data)
      })
    },
    
    editRegion({commit}, region) {
      return RegionService.saveRegion(region).then(data => {
        commit("setNewOptionForRegion", data)
      })
    }
  },
}