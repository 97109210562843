import { $_app_server } from '@/_helpers'

export const priceService = {
  save,
  load,
  list,
  visit,
  pdf,
  zip,
  loadBonus
}

let f_resp = (response) => response.data;

function save(year, month, user, project, point, edit) {
  return $_app_server.post(`price?year=${year}&month=${month}&user_id=${user}&project_id=${project}&point_id=${point}`, edit).then(f_resp)
}

function load(filename) {
  return $_app_server.post("price/load", { filename: filename }).then(f_resp)
}

function list() {
  return $_app_server.get("price/list").then(f_resp)
}

function visit(year, month, user) {
  return $_app_server.get(`price/visits?year=${year}&month=${month}&user_id=${user}`).then(f_resp)
}

function pdf(year, month, user, sp, act) {
  return $_app_server.get(`price/pdf?year=${year}&month=${month}&user_id=${user}&sp_id=${sp}&act_id=${act}`, {
    responseType: 'blob'
  })
}

function zip(year, month, user, sp, act) {
  return $_app_server.get(`price/zip?year=${year}&month=${month}&sp_id=${sp}&act_id=${act}`)
}

function loadBonus(year, month, filename) {
  return $_app_server.post(`price/load/bonus?year=${year}&month=${month}&filename=${filename}`).then(f_resp)
}