import Vue from 'vue'
import Vuex from 'vuex'

import { authentication } from './authentication.module'
import { user } from './user.module'
import { sp } from './sp.module'
import { act } from './act.module'
import { city } from './city.module'
import { street } from './streets.module'
import { house } from './houses.module'
import { region } from './regions.module'
import { roles } from './roles.module'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    authentication,
    user,
    sp,
    act,
    city,
    street,
    house,
    region,
    roles,
  }
});