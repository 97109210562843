import { $_app_server } from '@/_helpers'

export const housesService = {
  getAllHouses,
  removeHouse,
  addHouse
}


function getAllHouses() {
  return $_app_server.get("address/house/housesList")
    .then(response => {
      return response.data
    })
}

function removeHouse(id) {
  return $_app_server.delete(`address/house/deleteHouse/${id}`).then((response) => response.data)
}

function addHouse(obj) {
  return $_app_server.post(`address/house/addHouse`, obj).then((response) => response.data)
}

