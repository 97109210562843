<template lang="pug">
b-form-group( :label="label" label-cols="4" label-align="right" )
  b-input-group-append
    b-form-datepicker( :value="value" :state="valid" locale="ru-RU" start-weekday="1" size="sm" label-no-date-selected="Дата не выбрана" no-flip right @input="input" )
</template>

<script>
export default {
  props: ['value', 'label', 'valid'],
  
  methods: {
    input(val) {
      this.$emit('input', val)
    },
  }
}
</script>