<template lang="pug">
div( class="loading-screen" )
  b-skeleton-table( :rows="rows || 7" :columns="columns || 5" :table-props="tableProps" hide-header )
</template>

<script>
export default {
  props: ['rows', 'columns'],

  data () {
    return {
      tableProps: { bordered: true, striped: true }
    }
  }
}
</script>