import { $_app_server } from '@/_helpers'

export const cityService = {
  getAllCities,
  saveCity,
  profile,
  remove,
  addCity,
  removeCity,
  editCity,
  loadCityByRegion
}


function getAllCities() {
  return $_app_server.get("address/city/citiesList")
    .then(response => {
      return response.data
    })
}

function profile(name) {
  return $_app_server.get(`исходник ${name}`).then((response) => response.data)
}

function saveCity(profile) {
  return $_app_server.post(`исходник`, profile).then((response) => response.data)
}

function remove(name) {
  return $_app_server.delete(`исходник ${name}`).then((response) => response.data)
}

function addCity(obj) {
  return $_app_server.post(`address/city/addCity`, obj).then((response) => response.data)
}

function removeCity(id) {
  return $_app_server.delete(`address/city/deleteCity/${id}`).then((response) => response.data)
}

function editCity(obj){
  return $_app_server.post(`address/city/editCity`, obj).then((response) => response.data)
}

function loadCityByRegion(id, network_id, project_id){
  return $_app_server.get(`address/city/getCityByRegion/${id}/${network_id}/${project_id}`).then((response) => response.data)
}