<template lang="pug">
b-form-group( :label="label" label-cols="4" label-align="right" )
  b-form-select( :value="value" :options="options" :value-field="valueField" :text-field="textField" :state="valid" size="sm" @change="change" :disabled="disabled")
</template>

<script>
export default {
  props: ['value', 'label', 'options', 'valueField', 'textField', 'valid', 'disabled'],

  model: {
    prop: 'value',
    event: 'change'
  },

  methods: {
    change(val) {
      this.$emit('change', val)
    },
  }
}
</script>