import { housesService } from "../_services";

export const house = {
  namespaced: true,
  
  state: {
    houses: [],
  },
  
  getters: {},

  mutations: {
    setHouses(state, list) {
      state.houses = list
      state.houses.sort((u1, u2) => u1.name > u2.name ? 1 : u1.name < u2.name ? -1 : 0);
    },

    deleteHouse(state, houseId) {
      let index = state.houses.findIndex((house) => house.id == houseId)
        if(index != -1) {
          state.houses.splice(index, 1)
        }
    },

    addNewHouse(state, house) {
      state.houses.push(house)
    },

    editHouseOptions(state, editHouse) {
      let index = state.houses.findIndex(elem => elem.id === editHouse.id)
      state.houses.splice(index, 1, editHouse)
    }
  },
  
  actions: {
    loadAllHouses({commit}) {
      return housesService.getAllHouses().then(data => {
        commit('setHouses', data)
      })
    },

    removeHouse({commit}, id) {
      return housesService.removeHouse(id).then(() => {
        commit("deleteHouse", id)
      })
    },

    addHouse({commit},  house) {
      return housesService.addHouse(house).then(data => {
        commit("addNewHouse", data)
      })
    },

    editHouse({commit}, house) {
      return housesService.addHouse(house).then(data => {
        commit("editHouseOptions", data)
      })
    }
  }
}