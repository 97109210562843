import Vue from 'vue'
import Router from 'vue-router'

import { AdminPages } from './admin.page'

// Containers
const DefaultContainer = () => import('@/layouts/DefaultContainer')

Vue.use(Router)

export default new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/login',
    },
    {
      path: '/login',
      name: 'login',
      meta: {
        label: "Вход",
        title: "Вход"
      },
      component: () => import('@/pages/Access/LoginPage')
    },
    {
      path: '/admin',
      redirect: '/admin/report',
      name: 'admin',
      meta: {
        label : "Администратор",
        title : "Администратор"
      },
      component: DefaultContainer,
      children: AdminPages
    }
  ]
})
