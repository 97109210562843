<template lang="pug">
b-button( variant="outline-info" class="mr-2" size="sm" v-on="$listeners" )
  b-icon( :icon="icon" small )
  span( v-if="$slots.default" class="ml-1")
    slot
</template>

<script>
export default {
  props: ['type'],

  computed: {
    icon() {
      switch (this.type) {
        case 'add':
          return 'plus-circle'

        case 'edit':
          return 'pencil'

        case 'remove':
          return 'x-circle'

        case 'filter':
          return 'funnel'
      
        default:
          return ''
      }
    }
  },
}
</script>