export const act = {
  namespaced: true,
  state: {
    acts: []
  },
  getters: {},
  actions: {},
  mutations: {
    setActs(state, list) {
      state.acts = [].concat(list)
    }
  }
}