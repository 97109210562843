<template lang="pug">
b-form-group( :label="label" label-cols="4" label-align="right" )
  b-form-input( :value="value" :state="valid" v-mask="mask" size="sm" @input="input" )
</template>

<script>
export default {
  props: ['value', 'label', 'mask', 'valid'],

  methods: {
    input(val) {
      this.$emit('input', val)
    },
  }
}
</script>