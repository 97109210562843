import { $_app_server } from '@/_helpers'

export const reportService = {
  file,
  projects
}

let f_resp = (response) => response.data;

function file(month, project) {
  return $_app_server.get("oldreport/data/xsl?month=" + month + "&project=" + project, {
    responseType: 'arraybuffer'
  })
}

function projects() {
  return $_app_server.get("oldreport/projects").then(f_resp)
}