<template lang="pug">
b-form-group( :label="label" label-cols="4" label-align="right" )
  b-form-input( :value="textValue" :list="id" :state="valid" :disabled="disabled" size="sm" autocomplete="off" @input="input" )
  b-form-datalist( :id="id" :options="mappedOptions" )
</template>

<script>
export default {
  props: ['value', 'label', 'options', 'valueField', 'textField', 'disabled', 'valid'],

  computed: {

    availableNetworkIDs() {
      return this.options.map(el => el.id)
    },

    isValidNetwork() {
      return this.hasValue(this.item.network) && this.availableNetworkIDs.includes(this.item.network)
    },

    id() {
      return 'input_list_' + Date.now()
    },

    textValue() {
      let foundedOption = this.value ? this.options.find(el => el[this.valueField] == this.value[this.valueField]) : null
      
      return foundedOption ? foundedOption[this.textField] : ''
    },

    mappedOptions() {
      return this.options.map(a => a[this.textField])
    },
  },

  methods: {
    input(text) {
      let foundedOption = this.options.find(el => el[this.textField].toLowerCase() == text.toLowerCase())
      let newValue = foundedOption ? foundedOption : ''
      this.$emit('input', newValue)
    },
  }
}
</script>