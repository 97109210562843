import { $_app_server } from '@/_helpers'

export const fileService = {
  uploadFile,
  uploadFiles,
  deleteFile
}

function uploadFile(formData) {
  return $_app_server.post('rfiles', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  .then(response => response.data)
}

function uploadFiles (formData, progressCallback) {
  return $_app_server.post('multyfiles', formData, {
    headers: {'Content-Type': 'multipart/form-data'},
    onUploadProgress: progressCallback
  })
  .then(response => response.data)
}

function deleteFile(filename) {
  return $_app_server.delete(`files/${filename}`).then(response => response.data)
}