const Report = () => import('@/pages/Admin/Report/Index')
const Price = () => import('@/pages/Admin/Price/Index')
const Bonus = () => import('@/pages/Admin/Price/BonusList')
const Visit = () => import('@/pages/Admin/Price/Visit')
const UserList = () => import('@/pages/Admin/User/UserList')
const Sps = () => import('@/pages/Admin/SP/List')
const Projects = () => import('@/pages/Admin/Project/ProjectsList')
const Networks = () => import('@/pages/Admin/Network/NetworksList')
const Sku = () => import('@/pages/Admin/SKU/SkuList')
const Category = () => import('@/pages/Admin/ProductCategory/ProductCategoryList')
const City = () => import('@/pages/Admin/City/CitiesList')
const Streets = () => import('@/pages/Admin/Streets/StreetsList')
const Houses = () => import('@/pages/Admin/Houses/HousesList')
const Regions = () => import('@/pages/Admin/Regions/RegionList')

const ShopPointList = () => import('@/pages/shopPoint/ShopPointList')
const PromoActionList = () => import('@/pages/promoAction/PromoActionList')

export const AdminPages = [
  {
    path: 'report',
    name: 'report',
    meta: {
      label : "Отчёт",
      title : "Отчёт"
    },
    components: {default: Report} 
  },
  {
    path: 'price',
    name: 'price',
    meta: {
      label : "Загрузка цен",
      title : "Загрузка цен"
    },
    components: {default: Price} 
  },
  {
    path: 'bonus',
    name: 'bonus',
    meta: {
      label : "Загрузка бонусов",
      title : "Загрузка бонусов"
    },
    components: {default: Bonus} 
  },
  {
    path: 'visit',
    name: 'visits',
    meta: {
      label : "Расчет посещений",
      title : "Расчет посещений"
    },
    components: {default: Visit} 
  },
  {
    path: 'users',
    name: 'users',
    meta: {
      label : "Список пользователей",
      title : "Список пользователей"
    },
    components: {default: UserList} 
  },
  {
    path: 'regions',
    name: 'regions',
    meta: {
      label : "Регионы",
      title : "Регионы"
    },
    components: {default: Regions} 
  },
  {
    path: 'city',
    name: 'cities',
    meta: {
      label : "Города",
      title : "Города"
    },
    components: {default: City} 
  },
  {
    path: 'streets',
    name: 'streets',
    meta: {
      label : "Улицы",
      title : "Улицы"
    },
    components: {default: Streets} 
  },
  {
    path: 'houses',
    name: 'houses',
    meta: {
      label : "Дома",
      title : "Дома"
    },
    components: {default: Houses} 
  },
  {
    path: 'sps',
    name: 'sps',
    meta: {
      label : "Список ИП",
      title : "Список ИП"
    },
    components: {default: Sps} 
  },
  {
    path: 'project',
    name: 'project',
    meta: {
      label : "Проекты",
      title : "Проекты"
    },
    components: {default: Projects} 
  },
  {
    path: 'network',
    name: 'network',
    meta: {
      label : "Ритейлеры",
      title : "Ритейлеры"
    },
    components: {default: Networks} 
  },
  {
    path: 'sku',
    name: 'sku',
    meta: {
      label : "Список SKU",
      title : "Список SKU"
    },
    components: {default: Sku} 
  },
  {
    path: 'category',
    name: 'catgegory',
    meta: {
      label : "Список категорий ассортимента",
      title : "Список категорий ассортимента"
    },
    components: {default: Category } 
  },
  {
    path: 'points',
    name: 'points',
    meta: {
      label : "Список торговых точек",
      title : "Список торговых точек"
    },
    components: { default: ShopPointList } 
  },
  {
    path: 'actions',
    name: 'actions',
    meta: {
      label : "Список акций",
      title : "Список акций"
    },
    components: { default: PromoActionList }
  }
]