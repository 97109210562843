import { $_app_server } from '@/_helpers'

export const actionService = {
  list,
  types,
  remove,
  create,
  update,
  products,
  saveproducts,
  getRegionsByTradePoints,
}

let f_resp = (response) => response.data;

function list() {
  return $_app_server.get("action/all").then(f_resp)
}

function types() {
  return $_app_server.get("action/types").then(f_resp)
}

function remove(id) {
  return $_app_server.delete(`action/${id}`).then(f_resp)
}

function create(obj) {
  return $_app_server.post(`action`, obj).then(f_resp)
}

function update(obj) {
  return $_app_server.put(`action`, obj).then(f_resp)
}

function products(id) {
  return $_app_server.get(`action/${id}/products`).then(f_resp)
}

function saveproducts(id, products) {
  return $_app_server.post(`action/${id}/products`, products).then(f_resp)
}

function getRegionsByTradePoints( projectId, networkId) {
  return $_app_server.get(`action/getRegions/${projectId}/${networkId}`).then(f_resp)
}