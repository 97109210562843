<template lang="pug">
b-card-header( class="p-0" )
  b-navbar
    b-navbar-brand {{ title }}
    slot 
</template>

<script>
export default {
  props: ['title'],
}
</script>