<template lang="pug">
b-modal( :visible="value" :title="title" :size="size ?? 'md'" hide-footer centered @change="change" )
  b-overlay( :show="loading" rounded="sm" )
    slot

    div( class="text-right" )
      b-button( v-if="skuDialog" variant="primary" class="mr-2" :disabled="!disabledFilterButton" @click="filter")
        b-icon( icon="funnel" small )
      b-button( variant="primary" :disabled="disabled" @click="save" ) Сохранить
</template>

<script>
export default {
  props: ['value', 'title', 'loading', 'size', 'submitActive', 'skuDialog','disabledFilterButton'],

  computed: {
    disabled() {
      return this.submitActive != null ? !this.submitActive : false
    },
  },

  methods: {
    change(newValue) {
      this.$emit('input', newValue)
    },
    
    save() {
      this.$emit('save')
    },

    filter() {
      this.$emit('filter')
    }
  }
}
</script>