<template lang="pug">
b-form-group( :label="label" label-cols="4" label-align="right" )
  b-form-checkbox( :checked="value" class="mt-2" switch @input="input" )
</template>

<script>
export default {
  props: ['value', 'label'],
  
  methods: {
    input(newValue) {
      this.$emit('input', newValue)
    },
  }
}
</script>