import Vue from 'vue'
import App from './App'
import {store} from '@/_store'
import router from './router/'
import VueMask from 'v-mask'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import { createPinia, PiniaVuePlugin } from 'pinia'

import './globalComponents'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/style/custom.css'
import './assets/style/app.css'

import AOS from "aos";
import "aos/dist/aos.css";

Vue.use(VueMask)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || to.meta.label || to.name;

  const publicPages = ['/', '/login']
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('access_token');
  
  if (authRequired && !loggedIn) {
      return next('/')
  }
  next()
})

new Vue({
  el: '#app',
  router,
  store,
  pinia,
  template: '<App/>',
  components: {
    App
  },
  created() {
    AOS.init({ offset: 20 })
  },
})
